import { BigNumber, BigNumberInBase } from '@injectivelabs/utils'

export * from './setup'

export const INJ_DENOM = 'inj'
export const INJ_MARKET_ID =
  '0xa508cb32923323679f29a032c70342c147c17d0145625922b0ef22e955c844c0'

export const DEFAULT_MARKET = 'BTC/USDT PERP'

export const ACTIVE = 'active'
export const ETH_COIN_GECKO_ID = 'ethereum'
export const USDT_COIN_GECKO_ID = 'tether'
export const UST_COIN_GECKO_ID = 'terrausd'
export const INJ_COIN_GECKO_ID = 'injective-protocol'
export const ZERO_TO_STRING = '0'
export const UI_DEFAULT_DECIMAL_PLACES = 3
export const TOKEN_HOLDINGS_DECIMAL_PLACES = 3
export const UI_DEFAULT_USD_DECIMAL_PLACES = 2
export const TOKEN_MINIMAL_AMOUNT = new BigNumber(1).shiftedBy(
  -TOKEN_HOLDINGS_DECIMAL_PLACES
)
export const UI_MINIMAL_DECIMAL_PLACES = 6
export const UI_MINIMAL_AMOUNT = new BigNumber(1).shiftedBy(
  -UI_MINIMAL_DECIMAL_PLACES
)
export const BIG_NUMBER_ROUND_DOWN_MODE = BigNumberInBase.ROUND_DOWN
export const PAGINATION_TOTAL_PAGE_LIMIT = 10000
export const DEFAULT_PAGINATION_TOTAL_COUNT = 1000000
export const UI_MAX_PAGINATION_LIMIT_COUNT = 50
export const UI_DEFAULT_PAGINATION_LIMIT_COUNT = 20
export const DEFAULT_FUNDING_RATE_DECIMALS = 4
export const UI_MINIMAL_ABBREVIATION_FLOOR = 1_000_000_000
export const TIME_AGO_DATE_FORMAT = "MMM-dd-yyyy HH:mm:ss aa 'UTC'xxx"
export const SPEND_POOL_DATE_FORMAT = "MMM dd yyyy HH:mm:ss 'UTC'xxx"
export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const DATE_PICKER_FORMAT = 'dd MMM yy'

export const MARKETS_HISTORY_CHART_SEVEN_DAYS = 154
export const MARKETS_HISTORY_CHART_ONE_HOUR = 60

export const COMMUNITY_SPEND_POOL_ADDRESS =
  'inj1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8dkncm8'

export const VALID_GLOBAL_SEARCH_FORMAT = 'validGlobalSearchFormat'
export const VALID_TRADE_HISTORY_SEARCH_FORMAT = 'validTradeHistorySearchFormat'
export const ZERO_ADDRESS = 'inj1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqe2hm49'
