import { Network, isDevnet, isTestnet } from '@injectivelabs/networks'
import { NetworkType } from '../../../types/network'

const NETWORK: Network =
  (import.meta.env.VITE_NETWORK as Network) || Network.Devnet
const IS_DEVNET = isDevnet(NETWORK)
const IS_TESTNET = isTestnet(NETWORK)

export const IS_DEVELOPMENT: boolean = process.env.NODE_ENV === 'development'
export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production'

const VITE_ENV = import.meta.env.VITE_ENV as string
const VITE_NETWORK = import.meta.env.VITE_NETWORK as string
export const MAINTENANCE_ENABLED: boolean =
  import.meta.env.VITE_MAINTENANCE_ENABLED === 'true'

const MAINNET_CHAIN_ID = 'injective-1'
const TESTNET_CHAIN_ID = 'injective-888'
const DEVNET_CHAIN_ID = 'injective-777'

export const NETWORK_CHAIN_ID_DISPLAY: Record<string, any> = {
  [Network.Mainnet]: MAINNET_CHAIN_ID,
  [Network.MainnetK8s]: MAINNET_CHAIN_ID,
  [Network.MainnetLB]: MAINNET_CHAIN_ID,
  [Network.MainnetOld]: MAINNET_CHAIN_ID,
  [Network.Staging]: MAINNET_CHAIN_ID,
  [Network.Testnet]: TESTNET_CHAIN_ID,
  [Network.TestnetSentry]: TESTNET_CHAIN_ID,
  [Network.TestnetK8s]: TESTNET_CHAIN_ID,
  [Network.TestnetOld]: TESTNET_CHAIN_ID,
  [Network.Devnet]: DEVNET_CHAIN_ID,
  [Network.Devnet1]: DEVNET_CHAIN_ID,
  [Network.Devnet2]: DEVNET_CHAIN_ID,
  [Network.Local]: DEVNET_CHAIN_ID
}

export const NETWORK_BASE_URL: Record<string, any> = {
  [Network.Mainnet]: 'https://explorer.injective.network/',
  [Network.MainnetK8s]: 'https://explorer.injective.network/',
  [Network.MainnetOld]: 'https://explorer.injective.network/',
  [Network.Staging]: 'https://explorer.injective.network/',
  [Network.MainnetLB]: 'https://explorer.injective.network/',
  [Network.Testnet]: 'https://testnet.explorer.injective.network/',
  [Network.TestnetK8s]: 'https://testnet.explorer.injective.network/',
  [Network.TestnetSentry]: 'https://testnet.explorer.injective.network/',
  [Network.TestnetOld]: 'https://testnet.explorer.injective.network/',
  [Network.Devnet]: 'https://devnet.explorer.injective.dev/',
  [Network.Devnet1]: 'https://devnet-1.explorer.injective.dev/',
  [Network.Devnet2]: 'https://devnet-2.explorer.injective.dev/',
  [Network.Local]: 'https://devnet.explorer.injective.dev/'
}

const NETWORK_DISPLAY: Record<string, any> = {
  [Network.Mainnet]: Network.Mainnet,
  [Network.MainnetK8s]: Network.Mainnet,
  [Network.MainnetOld]: Network.Mainnet,
  [Network.Staging]: Network.Mainnet,
  [Network.Testnet]: Network.Testnet,
  [Network.TestnetK8s]: Network.Testnet,
  [Network.TestnetSentry]: Network.Testnet,
  [Network.TestnetOld]: Network.Testnet,
  [Network.Devnet]: Network.Devnet,
  [Network.Devnet1]: Network.Devnet,
  [Network.Local]: Network.Devnet
}

export const MAINNET: NetworkType = {
  display: Network.Mainnet,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : Network.Mainnet,
  baseUrl: NETWORK_BASE_URL[Network.Mainnet]
}

export const DEVNET: NetworkType = {
  display: Network.Devnet,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : Network.Devnet,
  baseUrl: NETWORK_BASE_URL[Network.Devnet]
}

export const TESTNET: NetworkType = {
  display: Network.Testnet,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : Network.Testnet,
  baseUrl: NETWORK_BASE_URL[Network.Testnet]
}

const defaultNetwork = VITE_ENV
  ? IS_DEVNET
    ? DEVNET.network
    : IS_TESTNET
      ? TESTNET.network
      : MAINNET.network
  : NETWORK_DISPLAY[VITE_NETWORK]

const defaultDisplay = VITE_ENV
  ? IS_DEVNET
    ? DEVNET.display
    : IS_TESTNET
      ? TESTNET.display
      : MAINNET.display
  : NETWORK_DISPLAY[VITE_NETWORK]

const defaultBaseUrl = VITE_ENV
  ? IS_DEVNET
    ? DEVNET.baseUrl
    : IS_TESTNET
      ? TESTNET.baseUrl
      : MAINNET.baseUrl
  : NETWORK_BASE_URL[VITE_NETWORK]

export const DEFAULT_NETWORK: NetworkType = {
  display: defaultDisplay,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : defaultNetwork,
  baseUrl: defaultBaseUrl
} as NetworkType

export const NETWORKS: NetworkType[] = IS_DEVNET
  ? [MAINNET, DEVNET, TESTNET]
  : [MAINNET, TESTNET]
