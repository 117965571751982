import { default as _4044eEP6GkEjXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/404.vue?macro=true";
import { default as cw20balancesVbaajXtdDLMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/cw20balances.vue?macro=true";
import { default as indexvXyVz5aRdoMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index.vue?macro=true";
import { default as indexEUZSMbqG18Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/index.vue?macro=true";
import { default as redelegationsGWFq1Fg6BvMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/redelegations.vue?macro=true";
import { default as unbondings0kVDwAIjyJMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/unbondings.vue?macro=true";
import { default as stakingGwL6xUvCDPMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking.vue?macro=true";
import { default as transactionsjzG5jK9tBhMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/transactions.vue?macro=true";
import { default as _91account_93h20OclfX44Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account].vue?macro=true";
import { default as assettkt5EzHwRUMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/asset.vue?macro=true";
import { default as indexhs0SzgvDW3Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets/index.vue?macro=true";
import { default as smart_45contractsvF84AH4K8aMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets/smart-contracts.vue?macro=true";
import { default as assets5JxONUhLURMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets.vue?macro=true";
import { default as _91block_93ND4OLRWCUwMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/block/[block].vue?macro=true";
import { default as blocksZwwtmm0olXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/blocks.vue?macro=true";
import { default as _91code_93NM6XvLsoXVMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/code/[code].vue?macro=true";
import { default as codesueRhjKMO3LMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/codes.vue?macro=true";
import { default as community_45spend_45poolANt69RLQI6Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/community-spend-pool.vue?macro=true";
import { default as balancesVqR2wh4RcuMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/balances.vue?macro=true";
import { default as cw20balancesBvX8g4Hy1FMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/cw20balances.vue?macro=true";
import { default as indexCynXTUk3NKMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/index.vue?macro=true";
import { default as init9ehBsV5hSgMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/init.vue?macro=true";
import { default as token_45holdersYIa6oGcJMpMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/token-holders.vue?macro=true";
import { default as _91contract_93vTnbrrqMqsMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract].vue?macro=true";
import { default as contractstUQo6K8X0mMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contracts.vue?macro=true";
import { default as featured_45dappsS33etJ0izDMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/featured-dapps.vue?macro=true";
import { default as indexlDRZUe1rLcMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/index.vue?macro=true";
import { default as maintenance46QiZtYHjXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/maintenance.vue?macro=true";
import { default as funding_45rate_45historyrEsfQCTqhyMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/funding-rate-history.vue?macro=true";
import { default as indexD2h1ame7B6Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/index.vue?macro=true";
import { default as realtime_45funding_45rateDQiAJpsMCEMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/realtime-funding-rate.vue?macro=true";
import { default as _91marketId_93p6RU1ur5zMMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue?macro=true";
import { default as trading_45ruleseT2u2LGpWiMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/trading-rules.vue?macro=true";
import { default as marketsjMVx5f8CF2Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets.vue?macro=true";
import { default as raw_45txhsZgX3k56WMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/raw-tx.vue?macro=true";
import { default as _91tradeId_932IZbZckUG3Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/[tradeId].vue?macro=true";
import { default as indexwUMH3BoS4wMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/index.vue?macro=true";
import { default as index5cBaxeZBEYMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/index.vue?macro=true";
import { default as _91tradeId_93Il44fywLkaMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/[tradeId].vue?macro=true";
import { default as indexOtUprGVh8TMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/index.vue?macro=true";
import { default as trade_45historyITNpc6K06dMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history.vue?macro=true";
import { default as event_45logsJQMRIBwcOKMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/event-logs.vue?macro=true";
import { default as indexjAJ4CcZarrMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/index.vue?macro=true";
import { default as _91transaction_93vWk3ruUw4qMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction].vue?macro=true";
import { default as transactionsf9smiZHihmMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transactions.vue?macro=true";
export default [
  {
    name: _4044eEP6GkEjXMeta?.name ?? "404",
    path: _4044eEP6GkEjXMeta?.path ?? "/404",
    meta: _4044eEP6GkEjXMeta || {},
    alias: _4044eEP6GkEjXMeta?.alias || [],
    redirect: _4044eEP6GkEjXMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91account_93h20OclfX44Meta?.name ?? undefined,
    path: _91account_93h20OclfX44Meta?.path ?? "/account/:account()",
    meta: _91account_93h20OclfX44Meta || {},
    alias: _91account_93h20OclfX44Meta?.alias || [],
    redirect: _91account_93h20OclfX44Meta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account].vue").then(m => m.default || m),
    children: [
  {
    name: cw20balancesVbaajXtdDLMeta?.name ?? "account-account-cw20balances",
    path: cw20balancesVbaajXtdDLMeta?.path ?? "cw20balances",
    meta: cw20balancesVbaajXtdDLMeta || {},
    alias: cw20balancesVbaajXtdDLMeta?.alias || [],
    redirect: cw20balancesVbaajXtdDLMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/cw20balances.vue").then(m => m.default || m)
  },
  {
    name: indexvXyVz5aRdoMeta?.name ?? "account-account",
    path: indexvXyVz5aRdoMeta?.path ?? "",
    meta: indexvXyVz5aRdoMeta || {},
    alias: indexvXyVz5aRdoMeta?.alias || [],
    redirect: indexvXyVz5aRdoMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index.vue").then(m => m.default || m)
  },
  {
    name: stakingGwL6xUvCDPMeta?.name ?? undefined,
    path: stakingGwL6xUvCDPMeta?.path ?? "staking",
    meta: stakingGwL6xUvCDPMeta || {},
    alias: stakingGwL6xUvCDPMeta?.alias || [],
    redirect: stakingGwL6xUvCDPMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking.vue").then(m => m.default || m),
    children: [
  {
    name: indexEUZSMbqG18Meta?.name ?? "account-account-staking",
    path: indexEUZSMbqG18Meta?.path ?? "",
    meta: indexEUZSMbqG18Meta || {},
    alias: indexEUZSMbqG18Meta?.alias || [],
    redirect: indexEUZSMbqG18Meta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/index.vue").then(m => m.default || m)
  },
  {
    name: redelegationsGWFq1Fg6BvMeta?.name ?? "account-account-staking-redelegations",
    path: redelegationsGWFq1Fg6BvMeta?.path ?? "redelegations",
    meta: redelegationsGWFq1Fg6BvMeta || {},
    alias: redelegationsGWFq1Fg6BvMeta?.alias || [],
    redirect: redelegationsGWFq1Fg6BvMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/redelegations.vue").then(m => m.default || m)
  },
  {
    name: unbondings0kVDwAIjyJMeta?.name ?? "account-account-staking-unbondings",
    path: unbondings0kVDwAIjyJMeta?.path ?? "unbondings",
    meta: unbondings0kVDwAIjyJMeta || {},
    alias: unbondings0kVDwAIjyJMeta?.alias || [],
    redirect: unbondings0kVDwAIjyJMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/unbondings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: transactionsjzG5jK9tBhMeta?.name ?? "account-account-transactions",
    path: transactionsjzG5jK9tBhMeta?.path ?? "transactions",
    meta: transactionsjzG5jK9tBhMeta || {},
    alias: transactionsjzG5jK9tBhMeta?.alias || [],
    redirect: transactionsjzG5jK9tBhMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: assettkt5EzHwRUMeta?.name ?? "asset",
    path: assettkt5EzHwRUMeta?.path ?? "/asset",
    meta: assettkt5EzHwRUMeta || {},
    alias: assettkt5EzHwRUMeta?.alias || [],
    redirect: assettkt5EzHwRUMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/asset.vue").then(m => m.default || m)
  },
  {
    name: assets5JxONUhLURMeta?.name ?? undefined,
    path: assets5JxONUhLURMeta?.path ?? "/assets",
    meta: assets5JxONUhLURMeta || {},
    alias: assets5JxONUhLURMeta?.alias || [],
    redirect: assets5JxONUhLURMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets.vue").then(m => m.default || m),
    children: [
  {
    name: indexhs0SzgvDW3Meta?.name ?? "assets",
    path: indexhs0SzgvDW3Meta?.path ?? "",
    meta: indexhs0SzgvDW3Meta || {},
    alias: indexhs0SzgvDW3Meta?.alias || [],
    redirect: indexhs0SzgvDW3Meta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets/index.vue").then(m => m.default || m)
  },
  {
    name: smart_45contractsvF84AH4K8aMeta?.name ?? "assets-smart-contracts",
    path: smart_45contractsvF84AH4K8aMeta?.path ?? "smart-contracts",
    meta: smart_45contractsvF84AH4K8aMeta || {},
    alias: smart_45contractsvF84AH4K8aMeta?.alias || [],
    redirect: smart_45contractsvF84AH4K8aMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets/smart-contracts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91block_93ND4OLRWCUwMeta?.name ?? "block-block",
    path: _91block_93ND4OLRWCUwMeta?.path ?? "/block/:block()",
    meta: _91block_93ND4OLRWCUwMeta || {},
    alias: _91block_93ND4OLRWCUwMeta?.alias || [],
    redirect: _91block_93ND4OLRWCUwMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/block/[block].vue").then(m => m.default || m)
  },
  {
    name: blocksZwwtmm0olXMeta?.name ?? "blocks",
    path: blocksZwwtmm0olXMeta?.path ?? "/blocks",
    meta: blocksZwwtmm0olXMeta || {},
    alias: blocksZwwtmm0olXMeta?.alias || [],
    redirect: blocksZwwtmm0olXMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/blocks.vue").then(m => m.default || m)
  },
  {
    name: _91code_93NM6XvLsoXVMeta?.name ?? "code-code",
    path: _91code_93NM6XvLsoXVMeta?.path ?? "/code/:code()",
    meta: _91code_93NM6XvLsoXVMeta || {},
    alias: _91code_93NM6XvLsoXVMeta?.alias || [],
    redirect: _91code_93NM6XvLsoXVMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/code/[code].vue").then(m => m.default || m)
  },
  {
    name: codesueRhjKMO3LMeta?.name ?? "codes",
    path: codesueRhjKMO3LMeta?.path ?? "/codes",
    meta: codesueRhjKMO3LMeta || {},
    alias: codesueRhjKMO3LMeta?.alias || [],
    redirect: codesueRhjKMO3LMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/codes.vue").then(m => m.default || m)
  },
  {
    name: community_45spend_45poolANt69RLQI6Meta?.name ?? "community-spend-pool",
    path: community_45spend_45poolANt69RLQI6Meta?.path ?? "/community-spend-pool",
    meta: community_45spend_45poolANt69RLQI6Meta || {},
    alias: community_45spend_45poolANt69RLQI6Meta?.alias || [],
    redirect: community_45spend_45poolANt69RLQI6Meta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/community-spend-pool.vue").then(m => m.default || m)
  },
  {
    name: _91contract_93vTnbrrqMqsMeta?.name ?? undefined,
    path: _91contract_93vTnbrrqMqsMeta?.path ?? "/contract/:contract()",
    meta: _91contract_93vTnbrrqMqsMeta || {},
    alias: _91contract_93vTnbrrqMqsMeta?.alias || [],
    redirect: _91contract_93vTnbrrqMqsMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract].vue").then(m => m.default || m),
    children: [
  {
    name: balancesVqR2wh4RcuMeta?.name ?? "contract-contract-balances",
    path: balancesVqR2wh4RcuMeta?.path ?? "balances",
    meta: balancesVqR2wh4RcuMeta || {},
    alias: balancesVqR2wh4RcuMeta?.alias || [],
    redirect: balancesVqR2wh4RcuMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/balances.vue").then(m => m.default || m)
  },
  {
    name: cw20balancesBvX8g4Hy1FMeta?.name ?? "contract-contract-cw20balances",
    path: cw20balancesBvX8g4Hy1FMeta?.path ?? "cw20balances",
    meta: cw20balancesBvX8g4Hy1FMeta || {},
    alias: cw20balancesBvX8g4Hy1FMeta?.alias || [],
    redirect: cw20balancesBvX8g4Hy1FMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/cw20balances.vue").then(m => m.default || m)
  },
  {
    name: indexCynXTUk3NKMeta?.name ?? "contract-contract",
    path: indexCynXTUk3NKMeta?.path ?? "",
    meta: indexCynXTUk3NKMeta || {},
    alias: indexCynXTUk3NKMeta?.alias || [],
    redirect: indexCynXTUk3NKMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/index.vue").then(m => m.default || m)
  },
  {
    name: init9ehBsV5hSgMeta?.name ?? "contract-contract-init",
    path: init9ehBsV5hSgMeta?.path ?? "init",
    meta: init9ehBsV5hSgMeta || {},
    alias: init9ehBsV5hSgMeta?.alias || [],
    redirect: init9ehBsV5hSgMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/init.vue").then(m => m.default || m)
  },
  {
    name: token_45holdersYIa6oGcJMpMeta?.name ?? "contract-contract-token-holders",
    path: token_45holdersYIa6oGcJMpMeta?.path ?? "token-holders",
    meta: token_45holdersYIa6oGcJMpMeta || {},
    alias: token_45holdersYIa6oGcJMpMeta?.alias || [],
    redirect: token_45holdersYIa6oGcJMpMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/token-holders.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contractstUQo6K8X0mMeta?.name ?? "contracts",
    path: contractstUQo6K8X0mMeta?.path ?? "/contracts",
    meta: contractstUQo6K8X0mMeta || {},
    alias: contractstUQo6K8X0mMeta?.alias || [],
    redirect: contractstUQo6K8X0mMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contracts.vue").then(m => m.default || m)
  },
  {
    name: featured_45dappsS33etJ0izDMeta?.name ?? "featured-dapps",
    path: featured_45dappsS33etJ0izDMeta?.path ?? "/featured-dapps",
    meta: featured_45dappsS33etJ0izDMeta || {},
    alias: featured_45dappsS33etJ0izDMeta?.alias || [],
    redirect: featured_45dappsS33etJ0izDMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/featured-dapps.vue").then(m => m.default || m)
  },
  {
    name: indexlDRZUe1rLcMeta?.name ?? "index",
    path: indexlDRZUe1rLcMeta?.path ?? "/",
    meta: indexlDRZUe1rLcMeta || {},
    alias: indexlDRZUe1rLcMeta?.alias || [],
    redirect: indexlDRZUe1rLcMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenance46QiZtYHjXMeta?.name ?? "maintenance",
    path: maintenance46QiZtYHjXMeta?.path ?? "/maintenance",
    meta: maintenance46QiZtYHjXMeta || {},
    alias: maintenance46QiZtYHjXMeta?.alias || [],
    redirect: maintenance46QiZtYHjXMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: marketsjMVx5f8CF2Meta?.name ?? undefined,
    path: marketsjMVx5f8CF2Meta?.path ?? "/markets",
    meta: marketsjMVx5f8CF2Meta || {},
    alias: marketsjMVx5f8CF2Meta?.alias || [],
    redirect: marketsjMVx5f8CF2Meta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets.vue").then(m => m.default || m),
    children: [
  {
    name: funding_45rate_45historyrEsfQCTqhyMeta?.name ?? "markets-funding-rate-history",
    path: funding_45rate_45historyrEsfQCTqhyMeta?.path ?? "funding-rate-history",
    meta: funding_45rate_45historyrEsfQCTqhyMeta || {},
    alias: funding_45rate_45historyrEsfQCTqhyMeta?.alias || [],
    redirect: funding_45rate_45historyrEsfQCTqhyMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/funding-rate-history.vue").then(m => m.default || m)
  },
  {
    name: indexD2h1ame7B6Meta?.name ?? "markets",
    path: indexD2h1ame7B6Meta?.path ?? "",
    meta: indexD2h1ame7B6Meta || {},
    alias: indexD2h1ame7B6Meta?.alias || [],
    redirect: indexD2h1ame7B6Meta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/index.vue").then(m => m.default || m)
  },
  {
    name: realtime_45funding_45rateDQiAJpsMCEMeta?.name ?? "markets-realtime-funding-rate",
    path: realtime_45funding_45rateDQiAJpsMCEMeta?.path ?? "realtime-funding-rate",
    meta: realtime_45funding_45rateDQiAJpsMCEMeta || {},
    alias: realtime_45funding_45rateDQiAJpsMCEMeta?.alias || [],
    redirect: realtime_45funding_45rateDQiAJpsMCEMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/realtime-funding-rate.vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93p6RU1ur5zMMeta?.name ?? "markets-spot-marketId",
    path: _91marketId_93p6RU1ur5zMMeta?.path ?? "spot/:marketId()",
    meta: _91marketId_93p6RU1ur5zMMeta || {},
    alias: _91marketId_93p6RU1ur5zMMeta?.alias || [],
    redirect: _91marketId_93p6RU1ur5zMMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  },
  {
    name: trading_45ruleseT2u2LGpWiMeta?.name ?? "markets-trading-rules",
    path: trading_45ruleseT2u2LGpWiMeta?.path ?? "trading-rules",
    meta: trading_45ruleseT2u2LGpWiMeta || {},
    alias: trading_45ruleseT2u2LGpWiMeta?.alias || [],
    redirect: trading_45ruleseT2u2LGpWiMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/trading-rules.vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93p6RU1ur5zMMeta?.name ?? "markets-futures-marketId",
    path: _91marketId_93p6RU1ur5zMMeta?.path ?? "/futures/:marketId()",
    meta: _91marketId_93p6RU1ur5zMMeta || {},
    alias: _91marketId_93p6RU1ur5zMMeta?.alias || [],
    redirect: _91marketId_93p6RU1ur5zMMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93p6RU1ur5zMMeta?.name ?? "markets-perpetual-marketId",
    path: _91marketId_93p6RU1ur5zMMeta?.path ?? "/perpetual/:marketId()",
    meta: _91marketId_93p6RU1ur5zMMeta || {},
    alias: _91marketId_93p6RU1ur5zMMeta?.alias || [],
    redirect: _91marketId_93p6RU1ur5zMMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93p6RU1ur5zMMeta?.name ?? "markets-binary-options-marketId",
    path: _91marketId_93p6RU1ur5zMMeta?.path ?? "/binary-options/:marketId()",
    meta: _91marketId_93p6RU1ur5zMMeta || {},
    alias: _91marketId_93p6RU1ur5zMMeta?.alias || [],
    redirect: _91marketId_93p6RU1ur5zMMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: raw_45txhsZgX3k56WMeta?.name ?? "raw-tx",
    path: raw_45txhsZgX3k56WMeta?.path ?? "/raw-tx",
    meta: raw_45txhsZgX3k56WMeta || {},
    alias: raw_45txhsZgX3k56WMeta?.alias || [],
    redirect: raw_45txhsZgX3k56WMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/raw-tx.vue").then(m => m.default || m)
  },
  {
    name: trade_45historyITNpc6K06dMeta?.name ?? undefined,
    path: trade_45historyITNpc6K06dMeta?.path ?? "/trade-history",
    meta: trade_45historyITNpc6K06dMeta || {},
    alias: trade_45historyITNpc6K06dMeta?.alias || [],
    redirect: trade_45historyITNpc6K06dMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history.vue").then(m => m.default || m),
    children: [
  {
    name: _91tradeId_932IZbZckUG3Meta?.name ?? "trade-history-derivatives-tradeId",
    path: _91tradeId_932IZbZckUG3Meta?.path ?? "derivatives/:tradeId()",
    meta: _91tradeId_932IZbZckUG3Meta || {},
    alias: _91tradeId_932IZbZckUG3Meta?.alias || [],
    redirect: _91tradeId_932IZbZckUG3Meta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/[tradeId].vue").then(m => m.default || m)
  },
  {
    name: indexwUMH3BoS4wMeta?.name ?? "trade-history-derivatives",
    path: indexwUMH3BoS4wMeta?.path ?? "derivatives",
    meta: indexwUMH3BoS4wMeta || {},
    alias: indexwUMH3BoS4wMeta?.alias || [],
    redirect: indexwUMH3BoS4wMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/index.vue").then(m => m.default || m)
  },
  {
    name: index5cBaxeZBEYMeta?.name ?? "trade-history",
    path: index5cBaxeZBEYMeta?.path ?? "",
    meta: index5cBaxeZBEYMeta || {},
    alias: index5cBaxeZBEYMeta?.alias || [],
    redirect: index5cBaxeZBEYMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/index.vue").then(m => m.default || m)
  },
  {
    name: _91tradeId_93Il44fywLkaMeta?.name ?? "trade-history-spot-tradeId",
    path: _91tradeId_93Il44fywLkaMeta?.path ?? "spot/:tradeId()",
    meta: _91tradeId_93Il44fywLkaMeta || {},
    alias: _91tradeId_93Il44fywLkaMeta?.alias || [],
    redirect: _91tradeId_93Il44fywLkaMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/[tradeId].vue").then(m => m.default || m)
  },
  {
    name: indexOtUprGVh8TMeta?.name ?? "trade-history-spot",
    path: indexOtUprGVh8TMeta?.path ?? "spot",
    meta: indexOtUprGVh8TMeta || {},
    alias: indexOtUprGVh8TMeta?.alias || [],
    redirect: indexOtUprGVh8TMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91transaction_93vWk3ruUw4qMeta?.name ?? undefined,
    path: _91transaction_93vWk3ruUw4qMeta?.path ?? "/transaction/:transaction()",
    meta: _91transaction_93vWk3ruUw4qMeta || {},
    alias: _91transaction_93vWk3ruUw4qMeta?.alias || [],
    redirect: _91transaction_93vWk3ruUw4qMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction].vue").then(m => m.default || m),
    children: [
  {
    name: event_45logsJQMRIBwcOKMeta?.name ?? "transaction-transaction-event-logs",
    path: event_45logsJQMRIBwcOKMeta?.path ?? "event-logs",
    meta: event_45logsJQMRIBwcOKMeta || {},
    alias: event_45logsJQMRIBwcOKMeta?.alias || [],
    redirect: event_45logsJQMRIBwcOKMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/event-logs.vue").then(m => m.default || m)
  },
  {
    name: indexjAJ4CcZarrMeta?.name ?? "transaction-transaction",
    path: indexjAJ4CcZarrMeta?.path ?? "",
    meta: indexjAJ4CcZarrMeta || {},
    alias: indexjAJ4CcZarrMeta?.alias || [],
    redirect: indexjAJ4CcZarrMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: transactionsf9smiZHihmMeta?.name ?? "transactions",
    path: transactionsf9smiZHihmMeta?.path ?? "/transactions",
    meta: transactionsf9smiZHihmMeta || {},
    alias: transactionsf9smiZHihmMeta?.alias || [],
    redirect: transactionsf9smiZHihmMeta?.redirect,
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transactions.vue").then(m => m.default || m)
  }
]