import { IS_DEVNET, IS_TESTNET } from '@shared/utils/constant'
import devnetTokens from '@/app/json/tokens/devnet.json'
import testnetTokens from '@/app/json/tokens/testnet.json'
import mainnetTokens from '@/app/json/tokens/mainnet.json'
import devnetValidators from '@/app/json/validators/devnet.json'
import testnetValidators from '@/app/json/validators/testnet.json'
import mainnetValidators from '@/app/json/validators/mainnet.json'

export const getTokens = () => {
  if (IS_DEVNET) {
    return devnetTokens
  }

  if (IS_TESTNET) {
    return testnetTokens
  }

  return mainnetTokens
}

export const getValidators = () => {
  if (IS_DEVNET) {
    return devnetValidators
  }

  if (IS_TESTNET) {
    return testnetValidators
  }

  return mainnetValidators
}

export const tokens = getTokens()
export const validators = getValidators()
